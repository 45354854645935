// vertical path bar overrides
.slds-progress_vertical {
  .slds-progress__item {
    &::before,
    &::after {
      content: none;
    }
  }
}

.metecho-create-project-progress {
  .slds-popover_tooltip {
    width: max-content;
  }
}
