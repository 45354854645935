.markdown-block {
  pre {
    margin: 0;
  }
}

.submit-guide {
  pre {
    overflow-x: hidden;
    text-overflow: ellipsis;
  }
}
