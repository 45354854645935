// Containers
// ==========

// Prevent unwanted horizontal scroll by overriding the 100vw applied by slds-grid_frame
.metecho-frame {
  min-width: 100%;
}

// Restrict fixed/absolute containers to half the viewport
// ie. used on toast containers to unblock header links
.half-container {
  @include restrict-container;

  left: 50%;
  transform: translateX(-50%);
}

// Restrict fixed/absolute containers to half the viewport
// ie. used on toast containers to unblock header links
.restricted-container {
  @include restrict-container;
}

.spinner-container.slds-spinner_container {
  z-index: 0;
}

.modal-tooltip {
  z-index: $z-index-toast;
}

.container-fill-space {
  align-items: stretch;
}

.content-fill-height {
  min-height: 100%;
}

.inline-container {
  display: inline-block;
}

// Set Modal height so changing content doesn't shift modal size
// As seen in Task Assign Modal
.modal-set-height {
  .slds-modal__content {
    height: 100%;
  }
}

// Remove extra space by overriding slds-p-right_medium when search component
// spans full-width on narrow screens
@media (max-width: $mq-medium) {
  .search-container {
    padding-right: 0;
  }
}
