// Commit History
// ==============

// override padding for slds table th first and last columns
#task-commits-table {
  .commits-sha {
    padding-left: 0.5rem;
  }

  .commits-timestamp {
    padding-right: 0.5rem;
  }
}
