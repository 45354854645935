// Form Elements
// =============

.metecho-textarea {
  min-height: $size-xx-small;
}

.form-grid {
  display: grid;
  grid-template-columns: 1fr auto;
}

.metecho-nested-checkboxes {
  // line up with above label
  // add grid + (faux checkbox + label margin)
  padding-left: var(
    --nested-checkboxes-padding-left,
    calc($square-icon-x-small-boundary + $spacing-large)
  );
}

.form-grid-three {
  display: grid;
  gap: $spacing-x-small;
  grid-template-columns: 43% 43% 1fr;

  // allow multi-line checkbox labels to line up on left
  .slds-checkbox__label {
    display: grid;
    grid-template-columns: min-content 1fr;
  }

  // when using grid on wrapper,
  // vertically align faux checkbox with with label
  .slds-checkbox_faux {
    margin-top: 0.35rem;
  }

  .slds-accordion__content & {
    margin-left: $square-icon-x-small-boundary;
  }

  .metecho-nested-checkboxes {
    --nested-checkboxes-padding-left: #{$spacing-large};
  }
}

[data-form='task-retrieve-changes'] {
  display: grid;
  height: 100%;

  .success-highlight {
    background-color: $color-background-highlight;
  }

  .slds-accordion__section {
    --slds-c-accordion-section-spacing-block-start: #{$spacing-x-small};
    --slds-c-accordion-section-spacing-block-end: #{$spacing-x-small};
    --slds-c-accordion-section-spacing-inline-start: #{$spacing-medium};
    --slds-c-accordion-section-spacing-inline-end: #{$spacing-medium};
  }

  .accordion-no-padding {
    .slds-is-open {
      .slds-accordion__section {
        --slds-c-accordion-section-spacing-inline-start: #{$spacing-large};
        --slds-c-accordion-section-spacing-inline-end: 0;
      }
    }
  }

  &.has-checkboxes {
    .slds-accordion__summary {
      @include icon-checkbox-layout;
    }
  }

  .slds-button.slds-accordion__summary-action {
    &:focus {
      svg {
        outline: 2px solid $color-border-brand-dark;
      }
    }
  }

  .grow-inner-item {
    .slds-accordion__summary-action > .slds-truncate {
      flex-grow: 1;
    }
  }
}

[data-form='create-epic-branch'] {
  .slds-listbox__status {
    padding: $spacing-xx-small $spacing-x-small;

    > span {
      margin: 0;
      padding: 0;
    }
  }
}
