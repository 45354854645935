// Data Display Helpers
// ====================

.light-bordered-row {
  border-top: 1px solid $color-border-separator-alt;
}

.vertical-separator {
  border-right: 1px solid $color-border-separator-alt;
  height: 100%;
  width: 1px;

  .hide-separator + & {
    display: none;
  }

  .show-separator + & {
    display: inline;
  }
}

.v-align-center {
  vertical-align: middle;
}

.icon-text-block {
  display: inline-block;
}

@keyframes fadeout {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.metecho-transition-in {
  animation: fadein $duration-slowly ease-in forwards;
}

.metecho-transition-out {
  animation: fadeout $duration-slowly ease-in 2s forwards;
}

// Override the padding on components with default padding like tab panels
.outdented_medium {
  margin: 0 ($spacing-medium * -1);
  width: calc(100% + (#{$spacing-medium} * 2));
}
