// Dataset Capture Panels
// ======================

.metecho-modal-inner-content {
  display: grid;
  overflow-y: hidden;

  @media (min-width: $mq-large) {
    grid-template-rows: var(--modal-content-rows, minmax(0, auto) 1fr);
    max-height: 100%;
  }
}

.metecho-scroll-panel-container {
  overflow-y: hidden;

  .slds-card {
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
  }

  .slds-card__body {
    overflow-y: hidden;

    > div {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }

  &.metecho-existing-data-remove {
    .slds-card__body {
      > div {
        overflow-y: auto;
      }
    }
  }
}

.metecho-nested-items {
  // faux indent of the wrapped lines
  margin-inline-start: 2ch;
  text-indent: -2ch;
}

.metecho-scroll-panel {
  overflow-y: auto;
}

// Constrain height here instead of on the grid template so row will collapse
// if not available
.metecho-existing-data-remove {
  @media (min-width: $mq-large) {
    max-height: 30vh;
  }
}

// Layout of the Data Options and Selected Data panels
.metecho-form-panels {
  display: grid;
  gap: #{$spacing-large};
  grid-template-columns: var(--form-panels-columns, 1fr);

  @media (min-width: $mq-large) {
    --form-panels-columns: 1fr 1fr;

    grid-row: 2; // incase the existing data to remove box is hidden
    overflow-y: hidden;
  }
}
