.slds-table_header-hidden {
  thead {
    clip: rect(0 0 0 0);
    height: 1px;
    overflow: hidden;
    position: absolute;
    margin: -1px;
    text-transform: none;
    white-space: nowrap;
    width: 1px;
  }
}

// Override React LDS table header action cell padding
//   to help line up checkboxes.
.align-checkboxes {
  .slds-th__action {
    padding-left: 0;
    padding-right: 0;
  }
}

// Override React LDS checkbox background color change on hover inside of table headers
.align-checkboxes .slds-th__action:focus,
.slds-th__action:hover {
  background-color: inherit;
}

// Override LDS table cell padding to make click target fill the whole row
.table-row-targets {
  label {
    cursor: pointer;
  }

  .slds-checkbox__label {
    padding: $spacing-small $spacing-medium;
  }

  &.slds-table th:first-child,
  &.slds-table td:first-child {
    padding: 0;
  }
}

.truncated-cell {
  max-width: 10px; // needs any max-width to work
  overflow: hidden;
  text-overflow: ellipsis;

  // A div is being added inside to wrap content
  // truncate on the table cell w/non-fixed layout
  // only works on cell contents, not in nested divs
  & > div {
    display: contents;
  }
}

.complex-cell {
  vertical-align: top;

  #project-epics-table & {
    padding-bottom: $spacing-x_small;
    padding-top: $spacing-x_small;
  }
}

// Status Cells
// ------------

.status-cell {
  > div {
    display: flex;
  }
}

.status-cell-icon {
  display: flex;
}

// add a tiny top margin to create visual appearance of centered text
.status-cell-text {
  margin-top: $spacing-xxx-small;
}

.numbers-cell {
  text-align: right;
}
