// Welcome Mat Overrides and Additions
// ===================================

.welcome-container {
  height: 100%;
  min-height: 100%;

  @media (min-width: $mq-medium) {
    height: 100vh;
  }
}

.welcome-inner {
  @media (max-width: $mq-medium) {
    flex-wrap: wrap;
  }

  .welcome-container & {
    height: 100%;
    max-height: unset;
  }
}
