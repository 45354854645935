// Tour
// ====

// Overriding inline styles to add changes to hover and focus states

/* stylelint-disable declaration-no-important */
.react-joyride__tooltip {
  [data-action='primary'] {
    border: 1px solid $color-brand-dark !important;

    &:hover,
    &:focus {
      background-color: $color-text-link-hover !important;
      border: 1px solid $color-text-link-hover !important;
      box-shadow: 0 0 3px $color-brand-dark !important;
      outline: none !important;
    }
  }

  [data-action='back'] {
    &:hover,
    &:focus {
      background-color: $brand-light !important;
      border-color: $button-color-border-primary !important;
      box-shadow: 0 0 3px $button-color-border-primary !important;
      color: $color-brand-dark !important;
      outline: none !important;
    }
  }
}
/* stylelint-enable declaration-no-important */

// Override background image
.slds-popover_walkthrough .slds-popover__header {
  background-image: url('~@/img/popover-header.png');
}

.popover-wrapper {
  left: -($spacing-xx-small);
  position: absolute;
  top: -($spacing-small);

  .epic-progress & {
    top: -($spacing-medium);
  }

  .logo-popover > & {
    left: $spacing-large;
    top: $spacing-small;
  }

  .metecho-breadcrumb & {
    left: $spacing-large;
    top: -($var-spacing-x-small);
  }

  .project-list & {
    left: $spacing-none;
    top: $spacing-xxx-small;
  }

  .page-title > & {
    left: $spacing-large;
    top: $var-spacing-medium;
  }

  .heading & {
    top: -($spacing-medium);
  }

  .org-card > & {
    left: $spacing-xx-small;
    top: -($spacing-xx-small);
  }

  th & {
    left: -($spacing-x-small);
    top: -($spacing-xx-small);
  }

  th:first-child & {
    left: $var-spacing-x-small;
  }
}

.popover-button {
  background: $color-background-light;
  border-radius: 100%;
  border: 1px solid $palette-violet-90;
  box-shadow: 0 0 2px $palette-violet-65;
  color: $palette-violet-65;
  transform: scale(1); // needed to prevent subpixel rounding in Safari
  z-index: $z-index-default;

  .tour-alert & {
    fill: currentcolor;
  }

  &.is-viewed {
    border: 1px solid $palette-green-90;
    box-shadow: 0 0 2px $palette-green-70;
    color: $palette-green-70;
  }

  // Hover/Focus styles should only apply if on interactive element
  &[aria-haspopup]:hover,
  &[aria-haspopup]:focus {
    box-shadow: var(--sds-c-button-shadow-focus, 0 0 2px $color-brand-dark),
      0 0 2px $color-text-link-focus;
    color: $brand-background-dark;
  }
}

// Keep text from being truncated in table and page header
.slds-popover {
  cursor: auto;
  font-size: $font-size-3;
  font-weight: normal;
  line-height: $line-height-text;
  text-align: left;
  white-space: initial;
}
