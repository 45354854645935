// Collaborators
// =============

// Add/Edit Collaborator Modal
// ---------------------------
.collaborator-username {
  margin-left: $spacing-x-small;

  .collaborator-button.is-assigned & {
    color: $color-text-default;
  }

  .collaborator-button.is-selected & {
    color: $color-border-selection;
  }
}

.collaborator-button {
  &.is-assigned {
    background-color: $color-background-row-active;
  }

  &.is-selected {
    color: $color-border-selection;
    border: 1px solid;
    border-color: $color-border-selection;

    &:hover {
      box-shadow: inset 0 0 0 1px $color-border-selection;
    }
  }

  &:not(.is-assigned) {
    &:hover {
      border: 1px solid;
    }
  }

  // don't show a border on tables with checkboxes
  .table-row-targets & {
    &:hover {
      border-color: transparent;
    }
  }
}

// Assigned Collaborator Cards
// ---------------------------
.collaborator-card {
  h2 {
    align-items: center;
    display: inline-flex;
    font-size: $font-size-3;
    width: 100%;
  }
}

// Task Create PR form
// -------------------
.metecho-avatar-container {
  cursor: default;

  // display avatar inline with spans of text
  > div {
    display: inline-block;
  }
}
