// Header
// ======

.global-header {
  z-index: $z-index-sticky;
  border-radius: 0;
  background: $color-background-alt;
}

[data-logo-bit='end'] {
  color: $color-text-brand;
  font-weight: $font-weight-regular;
}

.page-header {
  border: none;
  border-radius: 0;
  box-shadow: none;
}

.walkthrough-metecho-name {
  margin: 3px;
}
